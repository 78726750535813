import {
  CookieReqContext,
  CookieResContext,
  getCookie,
  saveUtmCookie,
} from "utils/cookie-utiils"

type UtmData = {
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  campaignType?: string
  fbclid?: string
  adId?: string
  trackingId?: string
  referer?: string
  gclid?: string
  adName?: string
}

export function getUtmData(ctx: CookieReqContext): UtmData | undefined {
  let utmSource,
    utmCampaign,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    referer,
    gclid,
    adName,
    fbclid
  if (getCookie(ctx, "utmSource")) {
    utmSource = getCookie(ctx, "utmSource") as string
  }
  if (getCookie(ctx, "utmCampaign")) {
    utmCampaign = getCookie(ctx, "utmCampaign") as string
  }
  if (getCookie(ctx, "utmMedium")) {
    utmMedium = getCookie(ctx, "utmMedium") as string
  }
  const utmTerm = getCookie(ctx, "utmTerm")
  if (getCookie(ctx, "campaignType")) {
    campaignType = getCookie(ctx, "campaignType") as string
  }
  if (getCookie(ctx, "adId")) {
    adId = getCookie(ctx, "adId") as string
  }
  if (getCookie(ctx, "fbclid")) {
    fbclid = getCookie(ctx, "fbclid") as string
  }
  if (getCookie(ctx, "trackingId")) {
    trackingId = getCookie(ctx, "trackingId") as string
  }
  if (getCookie(ctx, "referer")) {
    referer = getCookie(ctx, "referer") as string
  }
  if (getCookie(ctx, "gclid")) {
    gclid = getCookie(ctx, "gclid") as string
  }
  if (getCookie(ctx, "adName")) {
    adName = getCookie(ctx, "adName") as string
  }
  return {
    utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    referer,
    fbclid,
    gclid,
    adName,
  } as UtmData
}

export function saveUtmData(ctx: CookieResContext, utmData: UtmData) {
  if (utmData.utmSource) {
    saveUtmCookie(ctx, "utmSource", utmData.utmSource, 60 * 60 * 24)
  }
  if (utmData.utmCampaign) {
    saveUtmCookie(ctx, "utmCampaign", utmData.utmCampaign, 60 * 60 * 24)
  }
  if (utmData.utmMedium) {
    saveUtmCookie(ctx, "utmMedium", utmData.utmMedium, 60 * 60 * 24)
  }
  if (utmData.utmTerm) {
    saveUtmCookie(ctx, "utmTerm", utmData.utmTerm, 60 * 60 * 24)
  }
  if (utmData.campaignType) {
    saveUtmCookie(ctx, "campaignType", utmData.campaignType, 60 * 60 * 24)
  }
  if (utmData.adId) {
    saveUtmCookie(ctx, "adId", utmData.adId, 60 * 60 * 24)
  }
  if (utmData.fbclid) {
    saveUtmCookie(ctx, "fbclid", utmData.fbclid, 60 * 60 * 24)
  }
  if (utmData.trackingId) {
    saveUtmCookie(ctx, "trackingId", utmData.trackingId)
  }
  if (utmData.referer) {
    saveUtmCookie(ctx, "referer", utmData.referer)
  }
  if (utmData.gclid) {
    saveUtmCookie(ctx, "gclid", utmData.gclid)
  }
  if (utmData.adName) {
    saveUtmCookie(ctx, "adName", utmData.adName)
  }
}
